.contact-link {
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  color: currentColor;
}

.contact-link:hover {
  color: #a3a3a3;
  padding-bottom: 2px;
  border-bottom: 1px solid #a3a3a3;
}
