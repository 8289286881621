.spacing {
  min-width: 15%;
}

.footer {
  color: var(--main-bg-color);
  background: var(--darkgrey);
  margin-top: 5%;
  padding: 2% 2% 2% 8%;
  line-height: 28px;
  display: flex;
  width: 90%;
  position: absolute;
}

.footer-navigation-button:hover {
  cursor: pointer;
  border-bottom: 1px solid white;
  padding-bottom: 1px;
}

.map-container {
  display: flex;
  justify-content: center;
}
