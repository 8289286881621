.hero {
  background-image: linear-gradient(90deg, var(--accent), var(--darkblue));
  padding: 10% 0 10% 0;
}

.hero > h1,
.hero > div {
  margin: 0 15% 0 15%;
  color: var(--main-bg-color);
}
